<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-card-code title="New Product">
      <div class="mx-2 mt-1">
        <!-- title & button -->
        <div class="card-header">
          <div>
            <b-card-title>{{ $t("user.new_user") }}</b-card-title>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="reset"
              variant="primary"
              @click="navigateToTable()"
            >
              {{ $t("common.back") }}
            </b-button>
          </div>
        </div>

        <b-card-body>
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
              <b-row>
                <!-- name -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('products.name')"
                    label-for="v-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="user.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('products.name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- internal Ref -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('user.surname')"
                    label-for="v-surname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Surname"
                      rules="required"
                    >
                      <b-form-input
                        v-model="user.surname"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('user.surname')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- provider ref -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('user.username')"
                    label-for="v-username"
                  >
                    <b-form-input
                      v-model="user.username"
                      :placeholder="$t('user.username')"
                    />
                  </b-form-group>
                </b-col>

                <!-- buy price -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('user.email')"
                    label-for="v-email"
                  >
                    <b-form-input
                      v-model="user.email"
                      :placeholder="$t('user.email')"
                    />
                  </b-form-group>
                </b-col>

                <!-- selling price -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('user.phone')"
                    label-for="v-phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                    >
                      <b-form-input
                        v-model="user.phone"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('user.phone')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- brand -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('user.role')"
                    label-for="v-role"
                  >
                    <b-form-select
                      v-model="user.role"
                      :placeholder="$t('user.role')"
                      :options="roles"
                      rules="required"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="user.role === 'MECHANICS'"
                  md="4"
                >
                  <b-form-group
                    :label="$t('customer.cp')"
                    label-for="v-postal_code"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Postal Code"
                      :rules="user.role === 'MECHANICS' ? 'required' : {}"
                    >
                      <b-form-input
                        v-model="user.cp"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('customer.cp')"
                        @input="changeAddress"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="user.role === 'MECHANICS'"
                  md="4"
                >
                  <b-form-group
                    :label="$t('customer.house')"
                    label-for="v-house_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="House Number"
                      :rules="user.role === 'MECHANICS' ? {
                        required: true,
                        numeric: true,
                      } : {}"
                    >
                      <b-form-input
                        v-model="user.house_number"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('customer.house')"
                        @input="changeAddress"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="user.role === 'MECHANICS'"
                  md="4"
                >
                  <b-form-group
                    :label="$t('customer.city')"
                    label-for="v-city"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      :rules="user.role === 'MECHANICS' ? 'required' : {}"
                    >
                      <b-form-input
                        v-model="user.city"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('customer.city')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="user.role === 'MECHANICS'"
                  md="12"
                >
                  <b-form-group
                    :label="$t('customer.address')"
                    label-for="v-address"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      :rules="user.role === 'MECHANICS' ? 'required' : {}"
                    >
                      <b-form-input
                        v-model="user.address"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('customer.address')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="outline-primary"
                    class="mr-1"
                    @click="showModalPassword = true"
                  >
                    {{ $t("common.reset_password") }}
                  </b-button>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="v-active">
                    <b-row class="px-1">
                      <span class="mr-2">{{
                        $t("new_product.input_fields.active")
                      }}</span>
                      <b-form-checkbox
                        v-model="user.status"
                        checked="true"
                        class=""
                        switch
                      />
                    </b-row>
                  </b-form-group>
                </b-col>

                <!-- submit -->
                <b-col
                  cols="12"
                  class="mt-4"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    {{ $t("common.submit") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </div>
      <b-modal
        v-model="showModalPassword"
        class="modal fade bd-example-modal-xl"
        hide-footer
        hide-header
        centered
        size="md"
      >
        <h5>{{ $t('common.reset_password') }}</h5>
        <b-row>
          <b-col
            class="mb-2"
          >
            <b-form-input
              type="password"
              v-model="user.password"
              :placeholder="$t('user.confirm_acc.new_password')"
            />
          </b-col>
          <b-col
            class="mb-2"
          >
            <b-form-input
              type="password"
              v-model="user.r_password"
              :placeholder="$t('user.confirm_acc.confirm_password')"
            />
          </b-col>
        </b-row>
        <b-row
          align-h="end"
          class="px-1"
        >
          <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="showModalPassword = false"
          >
            {{ $t('common.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            @click="saveNewPassword"
          >
            {{ $t('common.save') }}
          </b-button>
        </b-row>
      </b-modal>
    </b-card-code>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BCardBody,
  BFormSelect,
  BCardTitle,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import geoLocation from '@/models/geoLocation'
import generateToken from '@/auth/geoAuth'
import dbUser from '@/models/user'

export default {
  components: {
    BCardCode,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BFormSelect,
    BCardTitle,
    BFormCheckbox,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      required,
      roleOptionsRefresh: [],
      fillAddress: null,
      showModalPassword: false,
      user: {
        name: '',
        surname: '',
        username: '',
        email: '',
        phone: null,
        id: null,
        role: '',
        rbac_permissions: {},
        send_verification_email: true,
        status: false,
        address: '',
        house_number: '',
        cp: '',
        city: '',
        latitude: '',
        longitude: '',
      },
      roles: [
        { value: 'ADMIN', text: this.$i18n.t('common.admin') },
        { value: 'MECHANICS', text: this.$i18n.t('common.mechanics') },
        { value: 'ADMINISTRATION', text: this.$i18n.t('common.administration') },
        { value: 'FREELANCERS', text: 'FREELANCE' }],
    }
  },
  async created() {
    if (this.$route.params.id !== 'new') {
      try {
        this.user = await dbUser.getById({ userId: this.$route.params.id })
        if (this.user.status === 0) this.user.status = false
        else this.user.status = true
        // this.user.role = {
        //   value: this.user.role,
        //   text: this.$i18n.t(`comon.${this.user.role.toLowerCase()}`),
        // }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.error')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: `${this.$i18n.t('toast.user.error')}`,
          },
        })
      }
    }
  },
  methods: {
    navigateToTable() {
      this.$router.push({ path: '/users/users', query: { page: this.$route.query.page || 1 } })
    },
    async saveNewPassword() {
      try {
        this.user.userId = this.user.id ?? null
        this.user.token = null
        // eslint-disable-next-line no-unused-vars
        await dbUser.confirmAccount(this.user)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.done')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `${this.$i18n.t('toast.user.success_password')}`,
          },
        })
        this.showModalPassword = false
      } catch (e) {
        // eslint-disable-next-line
        const error = 'toast.user.' + e.response.data.message
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.error_reset_user')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: `${this.$i18n.t(error)}`,
          },
        })
      }
    },
    changeAddress() {
      const payload = { cp: this.user.cp, house: this.user.house_number }
      payload.cp = payload.cp.replace(/[^a-zA-Z0-9]/g, '')
      payload.house_number = payload.house.replace(/[^0-9]/g, '')
      if (payload.cp.length === 9 || payload.cp.length === 6) {
        if (payload.house.length > 0) {
          this.getLocation(payload)
        }
      }
    },
    async getLocation(info) {
      const bearer = await generateToken()
      const payload = {
        cp: info.cp,
        house_number: info.house_number,
        bearer,
      }
      this.fillAddress = await geoLocation.get(payload)
      // eslint-disable-next-line prefer-destructuring
      this.user.address = this.fillAddress.street
      // eslint-disable-next-line prefer-destructuring
      this.user.city = this.fillAddress.city
      this.user.latitude = this.fillAddress.latitude
      this.user.longitude = this.fillAddress.longitude
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.user.userId = this.user.id ?? null
            delete this.user.id
            if (this.user.status) this.user.status = 1
            else this.user.status = 0
            await dbUser.set(this.user)
            this.navigateToTable()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.new_user')}`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$i18n.t('toast.text.success')}`,
              },
            })
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.new_user')}`,
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$i18n.t('toast.text.error')}`,
              },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },
  },
}
</script>
